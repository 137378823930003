import React from "react";
import { Checkbox } from "antd";

const { itemHeight, itemSearchHeight, itemWidth, pdWidth } = Dict.map.baselibOptions;
const NoData = Loader.loadBaseComponent("NoData");
const InfiniteScrollLayout = Loader.loadBaseComponent("ReactVirtualized", "InfiniteScrollLayout");
const List = Loader.loadBusinessComponent("BaseLibComponents", "List");

@Decorator.withTab
class ContentView extends React.Component {
  constructor(props) {
    super(props);
    this.infiniteRef = React.createRef();
    this.state = {
      hasLoadMore: true
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.storeId === BaseStore.tab.currentId && nextProps.searchData !== this.props.searchData) {
      this.infiniteRef.current && this.infiniteRef.current.forceUpdateGrid();
    }
  }

  // 单个选中飞入动画
  checkItem = (e, item) => {
    Utils.stopPropagation(e);
    let { checkedIds } = this.props;
    const flag = e.target.checked;
    if (flag) {
      const suffix = window.location.pathname.split("/")[1];
      const target = document.getElementsByClassName(`select-list-toggle1-${suffix}`)[0];
      const start = {
        clientX: e.clientX,
        clientY: e.clientY
      };
      checkedIds.push(item.id);
      Utils.animateFly({
        start,
        url: item[`${this.props.type}Url`],
        target,
        speed: 800,
        posFix: {
          top: 15,
          left: 20
        }
      });
    } else {
      checkedIds = checkedIds.filter(v => v !== item.id);
    }
    this.props.onChecked(checkedIds);
    setTimeout(() => {
      this.infiniteRef.current.forceUpdateGrid();
    }, 100);
  };

  /**
   * @desc 获取列表高度
   */
  getItemHeight = () => {
    const { isSearch, type = "face", size, isNonMotorized } = this.props;
    let height = 0;
    // 对车辆卡片单独处理 normal - 48  large - 44  small - 6
    let heightItem = {
      small: isNonMotorized ? 27 : 6,
      normal: isNonMotorized ? 69 : 48,
      large: isNonMotorized ? 84 : 63
    };
    if (isSearch) {
      if (type === "vehicle") {
        height = itemSearchHeight[size] + 24 - heightItem[size];
      } else {
        height = itemSearchHeight[size];
      }
    } else {
      if (type === "vehicle") {
        height = itemSearchHeight[size] - heightItem[size];
      } else {
        height = itemHeight[size];
      }
    }
    return height;
  };
  loadMore = () => {
    const { loadMore, limit } = this.props;
    if (this.props.loadMore) {
      this.setState({ hasLoadMore: false }, () => {
        loadMore().then(length => {
          if (length === limit) {
            this.setState({ hasLoadMore: true });
          }
        });
      });
    }
  };
  render() {
    const {
      checkedIds = [],
      list = [],
      size,
      isSearch,
      searchData = {},
      type,
      initTime,
      isNonMotorized,
      limit,
      goPage
    } = this.props;
    return (
      <div className="baselib-list-wrapper">
        <InfiniteScrollLayout
          ref={this.infiniteRef}
          pdWidth={pdWidth[size]}
          itemWidth={itemWidth[size]}
          itemHeight={this.getItemHeight()}
          data={list}
          loadMore={this.loadMore}
          hasLoadMore={this.state.hasLoadMore && list.length >= limit}
          hasBackTop={true}
          renderItem={(v, index) => (
            <List
              data={v}
              size={size}
              searchData={searchData}
              time={searchData.startTime}
              type={type}
              detailModuleName={isSearch ? "resourceSearchDetail" : `${isNonMotorized ? "nonVehicle" : type}LibraryDetail`}
              isNonMotorized={isNonMotorized}
              goPage={goPage}
              checkboxItem={
                isSearch && (
                  <Checkbox
                    className="item-check"
                    checked={checkedIds.indexOf(v.id) > -1}
                    value={v.id}
                    disabled={v.latitude && v.longitude ? false : true}
                    onClick={e => this.checkItem(e, v)}
                  />
                )
              }
            />
          )}
        />
      </div>
    );
  }
}

export default ContentView;
